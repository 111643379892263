var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("truck-icon-list", {
                    attrs: {
                      carNumber1: _vm.carNumber1,
                      carNumber2: _vm.carNumber2,
                      carNumber3: _vm.carNumber3,
                      carNumber4: _vm.carNumber4,
                      carNumber5: _vm.carNumber5,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "a-col",
                { attrs: { span: 4 } },
                [
                  _c("dept-tree", {
                    ref: "deptTree",
                    attrs: { deptOptions: _vm.deptOptions },
                    on: { select: _vm.clickDeptNode },
                  }),
                ],
                1
              ),
              _c(
                "a-col",
                {
                  staticStyle: { "border-left": "1px solid #e8e8e8" },
                  attrs: { span: 20 },
                },
                [
                  _c(
                    "a-row",
                    {
                      staticStyle: {
                        "margin-left": "10px",
                        "margin-top": "15px",
                      },
                    },
                    [
                      _c("a-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "table-page-search-wrapper" },
                          [
                            _c(
                              "a-form",
                              { attrs: { layout: "inline" } },
                              [
                                _c(
                                  "a-row",
                                  { attrs: { gutter: 48 } },
                                  [
                                    _c(
                                      "a-col",
                                      { attrs: { md: 8, sm: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "车辆编号",
                                              prop: "vehicleId",
                                            },
                                          },
                                          [
                                            _c("a-input", {
                                              attrs: {
                                                placeholder: "请输入车辆编号",
                                                "allow-clear": "",
                                              },
                                              model: {
                                                value: _vm.queryParam.vehicleId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.queryParam,
                                                    "vehicleId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "queryParam.vehicleId",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { md: 8, sm: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "车辆内部ID",
                                              prop: "vehicleInnerId",
                                            },
                                          },
                                          [
                                            _c("a-input", {
                                              attrs: {
                                                placeholder: "请输入车辆内部ID",
                                                "allow-clear": "",
                                              },
                                              model: {
                                                value:
                                                  _vm.queryParam.vehicleInnerId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.queryParam,
                                                    "vehicleInnerId",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "queryParam.vehicleInnerId",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.advanced
                                      ? [
                                          _c(
                                            "a-col",
                                            { attrs: { md: 8, sm: 24 } },
                                            [
                                              _c(
                                                "a-form-item",
                                                {
                                                  attrs: {
                                                    label: "MAC地址",
                                                    prop: "mqttTopic",
                                                  },
                                                },
                                                [
                                                  _c("a-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入MAC地址",
                                                      "allow-clear": "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.queryParam
                                                          .mqttTopic,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.queryParam,
                                                          "mqttTopic",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "queryParam.mqttTopic",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { md: 8, sm: 24 } },
                                            [
                                              _c(
                                                "a-form-item",
                                                {
                                                  attrs: {
                                                    label: "车辆名称",
                                                    prop: "name",
                                                  },
                                                },
                                                [
                                                  _c("a-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入车辆名称",
                                                      "allow-clear": "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.queryParam.name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.queryParam,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "queryParam.name",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "a-col",
                                      {
                                        attrs: {
                                          md: (!_vm.advanced && 8) || 24,
                                          sm: 24,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "table-page-search-submitButtons",
                                            style:
                                              (_vm.advanced && {
                                                float: "right",
                                                overflow: "hidden",
                                              }) ||
                                              {},
                                          },
                                          [
                                            _c(
                                              "a-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  id: "tableQueryBtn",
                                                },
                                                on: { click: _vm.handleQuery },
                                              },
                                              [
                                                _c("a-icon", {
                                                  attrs: { type: "search" },
                                                }),
                                                _vm._v("查询"),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "8px",
                                                },
                                                on: { click: _vm.resetQuery },
                                              },
                                              [
                                                _c("a-icon", {
                                                  attrs: { type: "redo" },
                                                }),
                                                _vm._v("重置"),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticStyle: {
                                                  "margin-left": "8px",
                                                },
                                                on: {
                                                  click: _vm.toggleAdvanced,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.advanced
                                                        ? "收起"
                                                        : "展开"
                                                    ) +
                                                    " "
                                                ),
                                                _c("a-icon", {
                                                  attrs: {
                                                    type: _vm.advanced
                                                      ? "up"
                                                      : "down",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "a-toolbar",
                    {
                      attrs: { loading: _vm.loading, refresh: "", tid: "1" },
                      on: { refreshQuery: _vm.getList },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "table-operations",
                          staticStyle: { "margin-left": "10px" },
                        },
                        [
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:iot:vehicle:add"],
                                  expression: "['tenant:iot:vehicle:add']",
                                },
                              ],
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.createForm.handleAdd()
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _vm._v("新增 "),
                            ],
                            1
                          ),
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:iot:vehicle:edit"],
                                  expression: "['tenant:iot:vehicle:edit']",
                                },
                              ],
                              attrs: { type: "primary", disabled: _vm.single },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.createForm.handleUpdate(
                                    undefined,
                                    _vm.ids
                                  )
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "edit" } }),
                              _vm._v("修改 "),
                            ],
                            1
                          ),
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:iot:vehicle:remove"],
                                  expression: "['tenant:iot:vehicle:remove']",
                                },
                              ],
                              attrs: { type: "danger", disabled: _vm.multiple },
                              on: { click: _vm.handleDelete },
                            },
                            [
                              _c("a-icon", { attrs: { type: "delete" } }),
                              _vm._v("删除 "),
                            ],
                            1
                          ),
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:iot:vehicle:export"],
                                  expression: "['tenant:iot:vehicle:export']",
                                },
                              ],
                              attrs: { type: "primary" },
                              on: { click: _vm.handleExport },
                            },
                            [
                              _c("a-icon", { attrs: { type: "download" } }),
                              _vm._v("导出 "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("create-form", {
                    ref: "createForm",
                    attrs: {
                      vehicleCategoryOptions: _vm.vehicleCategoryOptions,
                      deptOptions: _vm.deptOptions,
                      automodeOptions: _vm.automodeOptions,
                      vehicleStatusOptions: _vm.vehicleStatusOptions,
                    },
                    on: { ok: _vm.getList },
                  }),
                  _c("a-table", {
                    attrs: {
                      loading: _vm.loading,
                      size: _vm.tableSize,
                      tid: "1",
                      rowKey: "id",
                      columns: _vm.columns,
                      "data-source": _vm.list,
                      "row-selection": {
                        selectedRowKeys: _vm.selectedRowKeys,
                        onChange: _vm.onSelectChange,
                      },
                      pagination: false,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "vehicleCategory",
                        fn: function (text, record) {
                          return _c("span", {}, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.vehicleCategoryFormat(record)) +
                                " "
                            ),
                          ])
                        },
                      },
                      {
                        key: "operation",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {},
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:;" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCheckRegMac(record)
                                    },
                                  },
                                },
                                [_vm._v("车辆注册 ")]
                              ),
                              _c("a-divider", { attrs: { type: "vertical" } }),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["tenant:iot:vehicleAbility:list"],
                                      expression:
                                        "['tenant:iot:vehicleAbility:list']",
                                    },
                                  ],
                                  attrs: { href: "javascript:;" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToAbility(record)
                                    },
                                  },
                                },
                                [_vm._v(" 车辆能力 ")]
                              ),
                              record.operatorId === _vm.sessionUserId
                                ? _c("a-divider", {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: ["tenant:iot:vehicle:edit"],
                                        expression:
                                          "['tenant:iot:vehicle:edit']",
                                      },
                                    ],
                                    attrs: { type: "vertical" },
                                  })
                                : _vm._e(),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["tenant:iot:vehicle:edit"],
                                      expression: "['tenant:iot:vehicle:edit']",
                                    },
                                  ],
                                  attrs: { href: "javascript:;" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.createForm.handleUpdate(
                                        record,
                                        undefined
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "edit" } }),
                                  _vm._v("修改 "),
                                ],
                                1
                              ),
                              _c("a-divider", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["tenant:iot:vehicle:remove"],
                                    expression: "['tenant:iot:vehicle:remove']",
                                  },
                                ],
                                attrs: { type: "vertical" },
                              }),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["tenant:iot:vehicle:remove"],
                                      expression:
                                        "['tenant:iot:vehicle:remove']",
                                    },
                                  ],
                                  attrs: { href: "javascript:;" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(record)
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "delete" } }),
                                  _vm._v("删除 "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                      {
                        key: "onlineText",
                        fn: function (text) {
                          return [
                            _c("online-status", { attrs: { text: text } }),
                          ]
                        },
                      },
                      {
                        key: "enabledText",
                        fn: function (text, record) {
                          return [
                            record.enabled
                              ? _c("span", [_vm._v("正常")])
                              : _c("span", [_vm._v("停用")]),
                          ]
                        },
                      },
                      {
                        key: "soc",
                        fn: function (text) {
                          return [
                            _c("battery-status", { attrs: { text: text } }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("a-pagination", {
                    staticClass: "ant-table-pagination",
                    attrs: {
                      "show-size-changer": "",
                      "show-quick-jumper": "",
                      current: _vm.queryParam.pageNum,
                      total: _vm.total,
                      "page-size": _vm.queryParam.pageSize,
                      showTotal: function (total) {
                        return "共 " + total + " 条"
                      },
                    },
                    on: {
                      showSizeChange: _vm.onShowSizeChange,
                      change: _vm.changeSize,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vxe-modal",
        {
          attrs: {
            title: "车辆注册",
            width: "500",
            height: "300",
            "show-zoom": false,
            resize: "",
            position: { top: "30%", left: "45%" },
          },
          on: {
            close: function ($event) {
              _vm.isCheckingRegMac = false
            },
          },
          model: {
            value: _vm.isCheckingRegMac,
            callback: function ($$v) {
              _vm.isCheckingRegMac = $$v
            },
            expression: "isCheckingRegMac",
          },
        },
        [
          _vm.spinning
            ? _c(
                "a-spin",
                { attrs: { tip: _vm.tips, spinning: _vm.spinning } },
                [_c("div", { staticClass: "spin-content" })]
              )
            : _c("div", { staticClass: "spin-result" }, [
                _vm._v(_vm._s(_vm.tips)),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }